import React from 'react';

import './App.css';

// custom components
import Root from 'components/Root';

function App() {
  return (
      <Root />
  );
}

export default App;
