export default (theme) => {
  const darkMode = theme.palette.type === "dark";
  const primaryColor = darkMode ? "#FFFFFF" : "#32325d";
  const secondaryColor = darkMode ? "#bdbdbd" : "#8898aa";

  return {
    primaryColor: {
      color: `${primaryColor} !important`,
    },
    secondaryColor: {
      color: `${secondaryColor} !important`,
    },
    checkbox: {
      color: "secondaryColor",
    },
    sms: {
      margin: "0px 0px 20px 0px",
      display: "inline-flex",
      background: "rgba(155,155,155,0.1)",
      alignItems: "center",
      borderRadius: 4,
      padding: "0px 8px",
      justifyContent: "center",
      border: `solid 1px ${secondaryColor}`,
    },
    card: {
      background: darkMode ? "rgba(255,255,255,0.1)" : "#f1f5f9",
      borderRadius: "4px",
    },
    interactor: {
      background: darkMode ? "rgba(255,255,255,0.1)" : "#f1f5f9",
      borderRadius: "4px",
      border: darkMode
        ? "solid 1px rgba(255,255,255,0.3)"
        : "solid 1px rgb(204, 213, 222)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 6,
    },
    container: {
      background: darkMode ? "black" : "#f1f5f9",
      flexGrow: 1,
      padding: 16,
    },
    notFound: {
      textAlign: "center",
      background: darkMode ? "black" : "#f1f5f9",
      flexGrow: 1,
      padding: 16,
    },
    advert: {
      background: darkMode ? "#212121" : "#272836",
      flexGrow: 1,
      padding: 16,
      color: "white",
      cursor: "pointer",
    },
    advertItem: {
      padding: 6,
      background: darkMode ? "black" : "#3D3E4A",
      textAlign: "center",
      borderRadius: 8,
      height: "calc(100% - 8px)",
    },
    loading: {
      width: "100vw",
      minHeight: "calc(var(--vh, 1vh) * 100)",
      background: "rgba(255,255,255,0.4)",
      backdropFilter: "blur(18px)",
      position: "absolute",
      top: 0,
    },
    item: {
      minHeight: "calc(var(--vh, 1vh) * 100)",
    },
    subtitle: {
      fontFamily: "poppins, sans-serif",
      fontStyle: "normal",
      textAlign: "center",
      fontSize: 16,
      lineHeight: 1.3,
      fontWeight: 400,
      color: primaryColor,
    },
    h1: {
      fontFamily: "poppins, sans-serif",
      fontStyle: "normal",
      textAlign: "center",
      fontSize: 20,
      lineHeight: 1.3,
      fontWeight: 400,
      color: primaryColor,
    },
    body: {
      fontWeight: 400,
      fontFamily: "poppins, sans-serif",
      color: secondaryColor,
      fontSize: 12,
      lineHeight: "21px",
    },
    caption: {
      fontStyle: "normal",
      color: secondaryColor,
      fontSize: 10,
    },
    h5: {
      fontFamily: "poppins, sans-serif",
      fontWeight: 600,
      fontSize: 28,
      lineHeight: 1,
    },
    h6: {
      fontFamily: "poppins, sans-serif",
      fontWeight: 300,
      fontStyle: "normal",
      fontSize: 35,
      lineHeight: 1,
    },
    h4: {
      fontFamily: "poppins, sans-serif",
      fontWeight: 600,
      fontSize: 15,
      lineHeight: 1,
    },
    ultimate: {
      fontFamily: "bc-alphapipe, sans-serif",
      fontWeight: 700,
      fontStyle: "normal",
    },
    paper: {
      maxWidth: 600,
      width: "100%",
      position: "relative",
      boxShadow:
        "0 7px 14px 0 rgba(50,50,93,0.10), 0 3px 6px 0 rgba(0,0,0,0.07)",
      borderRadius: 12,
      background: darkMode ? "#212121" : "white",
    },
    stripe: {
      padding: 15,
      border: "solid 1px rgba(155,155,155,0.3)",
      borderRadius: 6,
      marginTop: 10,
    },
    position: {
      border: `solid 1px ${primaryColor}`,
      display: "inline-block",
      padding: "2px 6px",
      marginBottom: 8,
      borderRadius: 4,
    },
  };
};
