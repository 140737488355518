export default (theme) => {
  const darkMode = theme.palette.type === 'dark';
  return ({
    root: {
      height: '100%',
      overflow: 'hidden',
      position: 'absolute',
      background: darkMode ? '#000000' : '#FFFFFF',
    }
  });
};

