import { Chip, Typography } from "@material-ui/core";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { SketchField, Tools } from "react-sketch2";
import styles from "./styles";

class Signature extends PureComponent {
  static propTypes = {
    classes: PropTypes.object,
    clearSignature: PropTypes.func,
    height: PropTypes.number,
    width: PropTypes.number,
    signature: PropTypes.any,
    getSignature: PropTypes.func,
    signatureHasChanged: PropTypes.func,
    color: PropTypes.string,
  };

  state = {
    value: null,
    hasChanged: false,
    init: false,
  };

  constructor(...args) {
    super(...args);
    this.sketch = React.createRef();
  }

  componentDidMount(...args) {
    const { getSignature } = this.props;
    if (!this.state.init) {
      this.state.init = true;
      getSignature(this.getSignature.bind(this));
    }
  }

  download() {
    const { onSignatureReady } = this.props;
    if (onSignatureReady)
      onSignatureReady(this.sketch.current.toDataURL(), new Date());
    this.sketch.current.clear();
  }

  getSignature() {
    if (this.sketch.current) {
      return this.sketch.current.toDataURL();
    }

    return;
  }

  handleChange(e) {
    const { signatureHasChanged } = this.props;
    console.log(e.target.value);
    signatureHasChanged();
    this.setState({
      hasChanged: true,
      value: e.target.value,
    });
  }

  render() {
    const {
      classes,
      height,
      width,
      signature,
      clearSignature,
      color,
    } = this.props;

    const { value } = this.state;

    return (
      <div style={{ flex: 1, position: "relative" }}>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: 0,
          }}
        >
          <Typography variant="h4" style={{ opacity: 0.1 }} color="inherit">
            Sign Here
          </Typography>
        </div>
        <div>
          <SketchField
            ref={this.sketch}
            width={"100%"}
            height={200}
            tool={Tools.Pencil}
            lineColor={color || "black"}
            lineWidth={3}
            onObjectAdded={this.handleChange.bind(this)}
            value={value}
            imageFormat="png"
          />
        </div>
        <Chip
          label="Clear"
          size="small"
          style={{
            position: "absolute",
            bottom: 4,
            right: 4,
            color: "inherit",
          }}
          variant="outlined"
          onClick={() => {
            clearSignature();
            this.sketch.current.clear();
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles)(Signature);
