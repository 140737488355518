import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

export default theme => ({
  container: {
    padding: 5,
  },
  cssLabel: {
    '&$cssFocused': {
      color: green[500],
    },
  },
  focused: {},
  answer1: {
    '&$focused $notchedOutline': {
      borderColor: green[500],
    },
  },
  answer2: {
    '&$focused $notchedOutline': {
      borderColor: red[500],
    },
  },
  answer3: {
    '&$focused $notchedOutline': {
      borderColor: blue[500],
    },
  },
  notchedOutline: {},
});
