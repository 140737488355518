export const GET_STUDIO_SESSION_INVITES = "GET_STUDIO_SESSION_INVITES";
export const CREATE_TRACKER = "CREATE_TRACKER";
export const ACKNOWLEDGE_STUDIO_SESSION_INVITE =
  "ACKNOWLEDGE_STUDIO_SESSION_INVITE";
export const GET_STUDIO_SESSION_DEMO_INVITES =
  "GET_STUDIO_SESSION_DEMO_INVITES";
export const SET_DARK_MODE = "SET_DARK_MODE";
export const UPLOAD = "UPLOAD";
export const UPDATE_JOB = "UPDATE_JOB";
export const UPDATE_PROJECT_MERCURY = "UPDATE_PROJECT_MERCURY";
