import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

const level = {
  1: {
    label: "Accept",
    color: "#4caf50",
    level: "success",
  },
  2: {
    label: "Decline",
    color: "#f44336",
    level: "error",
  },
  3: {
    label: "Hold",
    color: "#2196f3",
    level: "info",
  },
};

class AnswerConfirmation extends Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    action: PropTypes.object,
    close: PropTypes.func,
    contractor: PropTypes.string,
    onConfirm: PropTypes.func,
    fullScreen: PropTypes.bool.isRequired,
  };

  state = {
    notes: "",
  };

  render() {
    const {
      classes,
      action,
      open,
      close,
      fullScreen,
      contractor,
      onConfirm,
    } = this.props;

    const { notes } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {action ? (
          <>
            <DialogTitle id="responsive-dialog-title">
              You are about to{" "}
              <span style={{ color: level[action.eventStatusID].color }}>
                {level[action.eventStatusID].label.toLowerCase()}
              </span>
              . Do you confirm?
            </DialogTitle>
            <DialogContent>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Want to leave a message to {contractor}?
              </Typography>
              <TextField
                variant="outlined"
                style={{
                  background: "rgba(155,155,155,0.1)",
                  borderColor: "solid 1px rgba(155,155,155,0.3)",
                }}
                InputProps={{
                  classes: {
                    root: classes[`answer${action.eventStatusID}`],
                    focused: classes.focused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                multiline
                placeholder="Optional"
                value={notes}
                fullWidth
                onChange={(e) => this.setState({ notes: e.target.value })}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={close} style={{ textTransform: "none" }}>
                Cancel
              </Button>
              <Button
                style={{
                  background: level[action.eventStatusID].color,
                  color: "white",
                  textTransform: "none",
                }}
                onClick={() => {
                  onConfirm(action.eventStatusID, notes);
                }}
              >
                {level[action.eventStatusID].label}
              </Button>
            </DialogActions>
          </>
        ) : (
          []
        )}
      </Dialog>
    );
  }
}

export default withMobileDialog()(withStyles(styles)(AnswerConfirmation));
