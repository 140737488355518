export default () => ({
  container: {
    maxWidth: 1200,
    margin: "auto",
  },
  signatureBlock: {
    background: "rgba(155,155,155,0.1)",
    border: "solid 1px rgba(155,155,155,0.3)",
    borderRadius: "4px",
  },
  image: {
    opacity: 0.5,
  },
  grid: {
    paddingTop: 10,
  },
});
