import { DATA, GET, POST, PUT } from "constants/methods";

const service = "rhapsody";
const studioSessions = `/${service}/workSessions`;
const trackers = `/${service}/activities`;
const uploads = `/${service}/uploads`;

export function getStudioSessionInvites(token) {
  const url = `${studioSessions}/mercuryJob?token=${token}`;
  return {
    method: GET,
    url,
  };
}

export function getStudioSessionDemoInvites(previewToken, statusID, roster) {
  const url = `${studioSessions}/invitesDemo?token=${previewToken}&statusID=${statusID}&roster=${
    roster ? "true" : "false"
  }`;
  return {
    method: GET,
    url,
  };
}

export function acknowledgeStudioSessionInvite(id, body) {
  const url = `${studioSessions}/${id}/acknowledge`;
  return {
    method: PUT,
    body,
    url,
  };
}

export function updateJob(body) {
  const url = `/${service}/jobs/mercuryPublic`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateProjectMercury(body) {
  const url = `/${service}/projects/mercuryPublic`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function createTracker(body) {
  const url = `${trackers}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function upload(file) {
  const data = new FormData();
  data.append("file", file);

  const url = `${uploads}`;

  return {
    method: DATA,
    url,
    body: data,
  };
}
