// material-ui
import {
  createMuiTheme,
  MuiThemeProvider,
  responsiveFontSizes,
  withStyles,
} from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// components
import App from "components/App";
import NotificationCenter from "components/NotificationCenter";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import theme from "themes/theme";
import { REACT_APP_API } from "config";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  user: state.user,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  getStudioSessionInvites: (...args) =>
    dispatch(Actions.getStudioSessionInvites(...args)),
  getStudioSessionDemoInvites: (...args) =>
    dispatch(Actions.getStudioSessionDemoInvites(...args)),
  acknowledgeStudioSessionInvite: (...args) =>
    dispatch(Actions.acknowledgeStudioSessionInvite(...args)),
  createTracker: (...args) => dispatch(Actions.createTracker(...args)),
  setDarkMode: (...args) => dispatch(Actions.setDarkMode(...args)),
  upload: (...args) => dispatch(Actions.upload(...args)),
  updateJob: (...args) => dispatch(Actions.updateJob(...args)),
  updateProjectMercury: (...args) =>
    dispatch(Actions.updateProjectMercury(...args)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    app: PropTypes.object,
    setDarkMode: PropTypes.func,
    upload: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const urlParams = getJsonFromUrl(window.location);
    const split = urlParams.token.split("-");
    if (split[split.length - 1] === "v2") {
      let api = "";
      const newToken = urlParams.token.replace("-v2", "");
      if (REACT_APP_API.includes("alpha")) api = "alpha";
      if (REACT_APP_API.includes("demo")) api = "demo";
      if (REACT_APP_API.includes("live")) api = "live";

      window.location.replace(
        `https://play.${api}.rhapsody.la/?token=${newToken}`
      );
    }

    this.state = {
      urlParams,
      token: urlParams.token,
      preview: urlParams.preview,
      hideCalendar: urlParams.hideCalendar,
      answer: urlParams.answer,
      statusID: urlParams.statusID,
      projectID: urlParams.projectID,
      roster: urlParams.roster === "true",
      loading: true,
      notFound: false,
      timestamp: new Date().getTime(),
    };
  }

  componentWillMount() {
    const dm =
      window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)");
    dm.addListener(this.darkModeChanged.bind(this));
  }

  componentDidMount() {
    this.refresh();
  }

  darkModeChanged(dm) {
    const { setDarkMode } = this.props;
    setDarkMode(dm.matches);
  }

  async refresh() {
    const { token, preview, statusID, roster } = this.state;
    const { getStudioSessionInvites, getStudioSessionDemoInvites } = this.props;
    if (token) {
      const resp = await getStudioSessionInvites(token);
      if (resp.success) {
        this.setState({
          invite: resp.payload,
          loading: false,
          notFound: false,
        });
      } else {
        this.setState({ notFound: true });
      }
    }
    if (preview) {
      const resp = await getStudioSessionDemoInvites(preview, statusID, roster);
      if (resp.success) {
        this.setState({
          invite: resp.payload,
          loading: false,
          notFound: false,
        });
      } else {
        this.setState({ notFound: true });
      }
    }
  }

  render() {
    const {
      acknowledgeStudioSessionInvite,
      createTracker,
      app,
      updateJob,
      updateProjectMercury,
      upload,
    } = this.props;

    const {
      loading,
      invite,
      token,
      hideCalendar,
      answer,
      notFound,
      projectID,
    } = this.state;

    const urlParams = getJsonFromUrl(window.location);
    console.log(urlParams);

    let preview = false;
    if (urlParams.preview !== undefined) {
      preview = true;
    }

    let muiTheme = createMuiTheme({
      palette: {
        ...theme,
        type: app.darkMode ? "dark" : "light",
      },
      typography: {
        fontFamily: ["poppins, sans-serif"].join(","),
      },
      overrides: {
        MuiTypography: {
          // Name of the component ⚛️ / style sheet
          root: {
            // Name of the rule
            color: app.darkMode ? "white" : "black", // Some CSS
          },
        },
      },
    });

    muiTheme = responsiveFontSizes(muiTheme);

    const fromApp = urlParams.fromApp === "true";

    return (
      <MuiThemeProvider theme={muiTheme}>
        <NotificationCenter>
          {!fromApp ? (
            <Helmet>
              <meta name="apple-itunes-app" content="app-id=1493480787" />
              <meta
                name="google-play-app"
                content="app-id=la.wid.rhapsody_musician"
              />
            </Helmet>
          ) : (
            []
          )}
          <App
            location={window.location}
            darkMode={app.darkMode}
            urlParams={urlParams}
            projectID={projectID}
            preview={preview}
            invite={invite}
            token={token}
            upload={upload}
            loading={loading}
            notes={urlParams.notes}
            refresh={this.refresh.bind(this)}
            hideCalendar={hideCalendar}
            createTracker={createTracker}
            updateProjectMercury={updateProjectMercury}
            updateJob={updateJob}
            answer={answer}
            notFound={notFound}
            acknowledgeStudioSessionInvite={acknowledgeStudioSessionInvite}
          />
        </NotificationCenter>
      </MuiThemeProvider>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
