import * as Api from "api";
import {
  ACKNOWLEDGE_STUDIO_SESSION_INVITE,
  CREATE_TRACKER,
  GET_STUDIO_SESSION_DEMO_INVITES,
  GET_STUDIO_SESSION_INVITES,
  SET_DARK_MODE,
  UPDATE_JOB,
  UPDATE_PROJECT_MERCURY,
  UPLOAD,
} from "constants/app";
import { networkAction } from "helpers/network/networkAction";

export const createTracker = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_TRACKER, Api.createTracker, [body]);

export const updateJob = (body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_JOB, Api.updateJob, [body]);

export const updateProjectMercury = (body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PROJECT_MERCURY, Api.updateProjectMercury, [
    body,
  ]);

export const upload = (file) => async (dispatch) =>
  networkAction(dispatch, UPLOAD, Api.upload, [file]);

export const acknowledgeStudioSessionInvite = (id, body) => async (dispatch) =>
  networkAction(
    dispatch,
    ACKNOWLEDGE_STUDIO_SESSION_INVITE,
    Api.acknowledgeStudioSessionInvite,
    [id, body]
  );

export const getStudioSessionInvites = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_INVITES,
    Api.getStudioSessionInvites,
    [id]
  );

export const getStudioSessionDemoInvites = (
  previewToken,
  statusID,
  roster
) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_STUDIO_SESSION_DEMO_INVITES,
    Api.getStudioSessionDemoInvites,
    [previewToken, statusID, roster]
  );

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });
